import * as React from 'react'
import { Helmet } from 'react-helmet'
import BigButton from '../components/Ui/BigButton'
import Wolflayout from '../components/wolflayout'

// markup
const NotFoundPage = () => {
  return (
    <Wolflayout>
      <div>
        <Helmet>
          <title>Erreur 404 | Le Festif! de Baie-Saint-Paul</title>
        </Helmet>
      </div>
      <div className="content text-center h-96">
          <h1>Erreur 404</h1>
          <p className="mb-24">OUPS! La page que vous recherchez n'existe pas.</p>
          <div className='flex mx-auto w-full justify-center'>
            <BigButton text={'ACCUEIL'} href="/"/>
          </div>
      </div>
    </Wolflayout>
  )
}

export default NotFoundPage
